<template>
  <div class="page-box">
    <van-empty
      v-if="orderList.length === 0"
      description="暂无订单"
    />
    <template v-else>
      <div
        v-for="item in orderList"
        :key="item.orderNo"
        class="item-box box"
        @click="goOrderDetail(item)"
      >
        <div class="item-top box">
          <div class="text-a">
            订单编号：{{ item.orderNo }}
          </div>
          <div
            class="text-b"
            :class="'text-b-' + item.status"
          >
            {{ statusObj[item.status] }}
          </div>
        </div>
        <div class="item-img">
          <img
            :src="item.courseImg || 'https://cf-workphone.oss-cn-hangzhou.aliyuncs.com/wpUpload/88agoh4nqjn1671277520200.png'"
            alt=""
          >
        </div>
        <div class="item-text-a">
          {{ item.courseName }}
        </div>
        <div class="item-text-b">
          第{{ item.period || 0 }}课/共{{ item.totalPeriod || 0 }}课次
        </div>
        <div class="item-text-c">
          学员：{{ item.childName || '-' }}
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'Test',
  components: {
  },
  data () {
    return {
      orderList: [],
      total: 0,
      statusObj: {
        0: '未结束',
        1: '未结束',
        2: '已结束',
        3: '退课中',
        4: '已退课'
      }
    }
  },
  computed: {
  },
  created () {
    this.getList()
  },
  methods: {
    async getList () {
      let status = parseInt(this.$route.query.status || 0)
      let data = {
        pageNo: 1,
        pageSize: 500,
        status
      }
      let res = await this.$http.userApi.userOrderList(data)
      console.log(res, 'res')
      if (res.code == 200) {
        this.orderList = res.msg.data || []
      }
    },
    goOrderDetail (item) {
      this.$router.push('/order/detail?orderNo=' + item.orderNo)
    }
  }
}
</script>

<style lang="scss" scoped>
.page-box {
  padding: 15px;
  .item-box {
    width: 346px;
    padding: 0 15px;
    margin: 0 auto;
    min-height: 163px;
    border-radius: 17px;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
    margin-bottom: 18px;
    .item-top {
      width: 100%;
      line-height: 29px;
      border-bottom: 1px solid #ACAEBF;
      .text-a {
        font-size: 12px;
        color: #8F92A9;
        float: left;
      }
      .text-b {
        color: #FF7E00;
        font-size: 13px;
        float: right;
      }
      .text-b-2 {
        color: #CFD0E8;
      }
      .text-b-3 {
        color: #f00;
      }
      .text-b-4 {
        color: #CFD0E8;
      }
    }
    .item-img {
      width: 155px;
      height: 103px;
      border-radius: 17px;
      position: absolute;
      left: 15px;
      bottom: 15px;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .item-text-a {
      line-height: 27px;
      position: absolute;
      left: 178px;
      top: 44px;
      font-weight: 600;
      color: #3D4258;
      font-size: 18px;
    }
    .item-text-b {
      color: #8F92A9;
      font-size: 13px;
      position: absolute;
      left: 178px;
      top: 76px;
      line-height: 19px;
    }
    .item-text-c {
      color: #8F92A9;
      font-size: 13px;
      position: absolute;
      left: 178px;
      top: 99px;
      line-height: 19px;
    }
  }
}
</style>
